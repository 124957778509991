.kalfa-page-view {
  max-width: 720px;
  margin: 0 auto;
  margin-bottom: 100px;
}

:local(.cell) {
  background-color: rgba(255, 255, 255, 1);
  padding: 1em;
  position: relative;
  box-shadow: 0 2px 4px 0 #00000080;
  margin-bottom: 10px;
}