.klfa-toc-list-item.mdc-list-item {
  display: flex;
  cursor: pointer;
}

.klfa-toc-list-item .mdc-list-item__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  /* white-space: nowrap; */
}
.klfa-toc-list-item .mdc-list-item__primary-text {
  margin: 0;
}

.klfa-toc-list-item .mdc-list-item__primary-text::after,
.klfa-toc-list-item .mdc-list-item__primary-text::before {
  display: none;
}

.klfa-toc-list-item .mdc-list-item__secondary-text {
  margin-top: -0.4em;
}

.klfa-toc-list-item.klfa-indent {
  margin-left: 2em;
}

.klfa-toc-list-item .mdc-list-item__graphic {
  margin-right: 0.5em;
}

:local(.highlight) {
  background-color: #ffd40061 !important;
}

:local(.rightClickMenu) {
  position: fixed;
  z-index: 10;
}

:local(.rightClickMenu) div[role="menuitem"] {
  margin: 0;
  font-weight: normal;
}

/*
.mdc-list-item {
  font-family: Roboto;
  font-size: 1.1rem;
  font-weight: bold;
  margin: 5px;
  background-color: white;
  height: 50px;
  --mdc-theme-text-icon-on-background: black;
  --mdc-theme-text-secondary-on-background: black;
  --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.6)
} */
