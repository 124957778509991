:local(.image) {
  max-width: 100%;
  margin: 0 auto;
}

:local(.zoomIn) {
  cursor: zoom-in;
}

:local(.alignCenter) {
  text-align: center;
}