.mdc-select__native-control {
  background: white;
  border-radius: 5px;
  padding: 16px;
  width: 100%;
}

.mdc-dialog__container {
  width: 80% !important;
}

@media only screen and (max-width: 800px) {
  .mdc-dialog__container {
    width: 100% !important;
  }
}

.mdc-dialog__surface {
  width: 100% !important;
}