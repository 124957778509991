:local(.container) {
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
  /* width: 377px; */
}

:local(.header) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #4a4a4a;
  color: white;
  text-transform: uppercase;
  padding: 0 1rem;
  flex-shrink: 0;
  height: 56px;
}

:local(.righClickMenu) {
  position: fixed;
}

:local(.klfaAddFolder) img {
  width: 20px;
  height: 20px;
}

:local(.mdcList) {
  overflow-y: auto;
}

