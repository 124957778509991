:local(.container) {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 3px;
}

:local(.button) {
  background: white;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px 0;
  width: 25px;
  height: 25px;
  padding: 2px 0 0 2px;
  color: var(--mdc-theme-primary);
}

:local(.button) i{
  border-radius: 50%;
}

@media only screen and (min-width: 1270px) {
  :local(.container) {
    margin: 10px 0;
    margin-left: -10px;
    position: relative;
  }
  
  :local(.button) {
    width: 30px;
    height: 30px;
    padding: 7px 0 0 7px;
    position: absolute;
    top: -17px;
    left: -35px;
  }  
}

:local(.addButton) {
  margin-right: 10px;
}

:local(.container) :hover {
  background-color: #eaeaea;
  cursor: pointer;
}

:local(.buttonIcon) {
  margin: 0 !important;
}