:local(.mdc-floating-label) {
  color: white;
}

:local(.contentAndCodeWrapper) {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 1em;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

:local(.sidebarImage) {
  flex-basis: 87px;
  flex-grow: 0;
  flex-shrink: 0;
  background-size: cover;
}

:local(.sidebar) {
  background-color: var(--klfa-secondary);
  color: var(--mdc-theme-text-primary-on-light);
  width: 400px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100vh - 56px);
  position: relative;
  --mdc-theme-on-surface: white;
  --mdc-theme-text-primary-on-light: white;
  --mdc-theme-text-primary-on-background: white;
  --mdc-theme-text-secondary-on-background: white;
}

:local(.sidebarContent) {
  padding: 0 1rem;
}

:local(.tabsWrapper) {
  display: flex;
  justify-content: space-between;
  --mdc-theme-primary: white;

}

:local(.code) {
  padding: 1em;
  font-family: "Zapfino";
}

:local(.tabsWrapper) .mdc-tab-bar {
  margin: 0;
}

@media only screen and (max-width: 800px) {
  :local(.sidebar) {
    height: auto;
    width: 100%;
  }
}
