:local(.answersWrapper) {
  display: flex;
  width: 100%;
}

:local(.answersWrapper) .mdc-drawer {
  border: 0;
}

:local(.answersWrapper) .user {
  background-color: var(--klfa-color-red);
  color: white;
  cursor: pointer;
  padding-right: 0.5em;
  margin: 0 1em 0.5em 0;
  width: auto;
  display: flex;

  /* justify-content: space-between; */

  /* opacity: 0.8; */
}

:local(.answersWrapper) .user:hover {
  opacity: 1;
  color: white;
}

:local(.answersWrapper) .user.selected {
  color: white;
  opacity: 1;
}

:local(.answersWrapper) .user.correct {
  background-color: var(--klfa-color-green);
}

:local(.answersWrapper) .user .mdc-chip__icon--trailing {
  margin: 0 0 auto auto;
}

:local(.buttons) {
  display: flex;
  flex-direction: column;
  margin-right: 25px;
}

:local(.widget) {
  flex-grow: 1;
  min-height: 300px;
}

:local(.widget) img {
  width: 100%;
  height: auto;
}

:local(.placeholder) {
  flex: 1;
  display: flex;
  height: 100%;
  place-content: center center;
}

:local(.placeholder) span {
  align-self: center;
}
