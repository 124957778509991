:local(.topClasses) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

:local(.topClasses) h1 {
  font-size: 1.5em;
}


:local(.hr) {
  border-width: 0;
  margin: 1em 0;
  border-top: 1px solid var(--klfa-content-background);
}

:local(.cardsCollection) {
  display: grid;
  grid-template-columns: repeat(auto-fill, 288px);
  grid-gap: 1em;
}

.mdc-linear-progress {
  margin-left: 1em;
  flex: 1 1;
}
