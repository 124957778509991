.kalfa-cell-youtube {
  border: 5px solid #cf2203;
  background-color: #cf2203;
  max-width: 100%;
  margin: 0 auto;
}

.kalfa-cell-youtube img {
  margin: 10px;
}

.youtube-video {
  border: 5px solid #cf2203;
  background-color: #cf2203;
  border-top: none;
  height: 0;
  margin-bottom: 1em;
  padding-bottom: 56.25%;
  padding-top: 50px;
  position: relative;
}

.youtube-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}