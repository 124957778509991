.mdc-dialog__surface {
  max-width: 1000px !important;
}

@media print {
  .klfa-answers-dialog {
    display: none !important;
  }
}

.klfa-answers-dialog .mdc-dialog__footer {
  padding: 24px;
  align-items: center;
  background-color: #e1e1e1;
}

.klfa-answers-dialog-content {
  margin-top: 1em;
  max-height: 60vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.klfa-answers-dialog .klfa-answers-progress-bar--container {
  flex: 1 0 0;
  margin-top: 0;
  margin-right: 1em;
}

.klfa-answers-dialog .mdc-typography--headline6 {
  display: block;
  border-left: 2px solid var(--mdc-theme-primary);
  border-right: 2px solid var(--mdc-theme-primary);
  padding: 0 1em;
  margin-bottom: 1em;
}

.klfa-answers-dialog .klfa-answers-tab-container {
  display: flex;
}

.klfa-answers-dialog .klfa-answers-tab-container > * {
  flex: 1;
}

.klfa-answers-dialog-student {
  display: flex;
  align-items: center;
}

.klfa-answers-dialog-student:hover {
  background-color: #f1f1f1;
  border-radius: 5px;
  cursor: pointer;
}

.klfa-answers-dialog-student.mdc-chip-set {
  margin-bottom: 0;
}

.klfa-answers-dialog-student .klfa-answers-dialog-student-name {
  width: 25%;
}

.klfa-answers-dialog-student.__correct .klfa-answers-dialog-student-name,
.klfa-answers-student-essay.__correct .klfa-answers-student-name {
  color: white;
  background-color: var(--klfa-color-green);
}

.klfa-answers-dialog-student.__incorrect .klfa-answers-dialog-student-name,
.klfa-answers-student-essay.__incorrect .klfa-answers-student-name {
  color: white;
  background-color: var(--klfa-color-red);
}

.klfa-answers-student-essay {
  margin-bottom: 0.5em;
}

@media only screen and (min-width: 600px) {
  .klfa-answers-student-essay {
    display: flex;
  }
}

.klfa-answers-student-essay .name-chips{
  flex-basis: 20%;
  flex-grow: 0;
  flex-shrink: 0;
}

.klfa-answers-student-essay-data {
  background-color: #e1e1e1;
  padding: 0.5em;
  border-radius: 5px;
  flex-grow: 1;
  white-space: pre-line;
}

/* .klfa-answers-student-essay .klfa-answers-student-name {
  min-width: 200px;
} */

/* .klfa-answers-dialog-student-name__essay {
  width: auto;
} */

:local(.dialogFooter) .mdc-button {
  --mdc-theme-primary: var(--klfa-primary);
}
