._37sezTYJl9EjnfwBkjHreX {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  z-index: 100;
}

.kalfa-cell {
  position: relative;
}

.klfa-ready-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.klfa-ready-button button::before {
  display: none;
}

.rJOuid27DSk5wccSxucDQ {
  height: 25px;
}

@media only print {
  .klfa-answers--container {
    display: none !important;
  }
}

.klfa-answers--container {
  --klfa-answers-background: var(--klfa-content-background);
  --klfa-answers-background-hover: #c1c1c1;
  display: flex;
  align-items: center;
  background-color: var(--klfa-answers-background);
  padding: 0 0.5em 0 0.8em;
  border-radius: 25px;
  margin-top: 0.8em;
  position: relative;
  border: 0;
  width: 100%;
  outline: none;
  /* padding: .5em; */
}

.klfa-answers--container::before {
  position: absolute;
  display: block;
  top: -7px;
  left: 7px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--klfa-answers-background);
  content: "";
}

.klfa-answers--container:hover,
.klfa-answers--container:focus {
  background-color: var(--klfa-answers-background-hover);
  cursor: pointer;
}

.klfa-answers--container:hover::before,
.klfa-answers--container:focus::before {
  border-bottom-color: var(--klfa-answers-background-hover);
}

.klfa-answers--container .mdc-button {
  white-space: nowrap;
  margin-left: 1em;
  /* margin-right: .5em; */

  text-transform: uppercase !important;
  /* prevent overwrites by cell css */
}

.klfa-answers-progress-bar--container {
  display: flex;
  flex: 1 1;
  width: 100%;
  height: 6px;
  cursor: pointer;
  box-shadow: 0 0 3px 0 grey;
}

.klfa-answers-progress-bar--container > div {
  flex: 1 1 auto;
}

.klfa-answers__ready {
  width: 1%;
  background-color: var(--mdc-theme-primary);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.klfa-answers__receiving {
  width: 1%;
  background-color: var(--mdc-theme-secondary);
}

.klfa-answers__inactive {
  width: 100%;
  background-color: white;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.mdc-dialog__surface {
  max-width: 1000px !important;
}

@media print {
  .klfa-answers-dialog {
    display: none !important;
  }
}

.klfa-answers-dialog .mdc-dialog__footer {
  padding: 24px;
  align-items: center;
  background-color: #e1e1e1;
}

.klfa-answers-dialog-content {
  margin-top: 1em;
  max-height: 60vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.klfa-answers-dialog .klfa-answers-progress-bar--container {
  flex: 1 0;
  margin-top: 0;
  margin-right: 1em;
}

.klfa-answers-dialog .mdc-typography--headline6 {
  display: block;
  border-left: 2px solid var(--mdc-theme-primary);
  border-right: 2px solid var(--mdc-theme-primary);
  padding: 0 1em;
  margin-bottom: 1em;
}

.klfa-answers-dialog .klfa-answers-tab-container {
  display: flex;
}

.klfa-answers-dialog .klfa-answers-tab-container > * {
  flex: 1 1;
}

.klfa-answers-dialog-student {
  display: flex;
  align-items: center;
}

.klfa-answers-dialog-student:hover {
  background-color: #f1f1f1;
  border-radius: 5px;
  cursor: pointer;
}

.klfa-answers-dialog-student.mdc-chip-set {
  margin-bottom: 0;
}

.klfa-answers-dialog-student .klfa-answers-dialog-student-name {
  width: 25%;
}

.klfa-answers-dialog-student.__correct .klfa-answers-dialog-student-name,
.klfa-answers-student-essay.__correct .klfa-answers-student-name {
  color: white;
  background-color: var(--klfa-color-green);
}

.klfa-answers-dialog-student.__incorrect .klfa-answers-dialog-student-name,
.klfa-answers-student-essay.__incorrect .klfa-answers-student-name {
  color: white;
  background-color: var(--klfa-color-red);
}

.klfa-answers-student-essay {
  margin-bottom: 0.5em;
}

@media only screen and (min-width: 600px) {
  .klfa-answers-student-essay {
    display: flex;
  }
}

.klfa-answers-student-essay .name-chips{
  flex-basis: 20%;
  flex-grow: 0;
  flex-shrink: 0;
}

.klfa-answers-student-essay-data {
  background-color: #e1e1e1;
  padding: 0.5em;
  border-radius: 5px;
  flex-grow: 1;
  white-space: pre-line;
}

/* .klfa-answers-student-essay .klfa-answers-student-name {
  min-width: 200px;
} */

/* .klfa-answers-dialog-student-name__essay {
  width: auto;
} */

.EZqNNj6YX5K9XKdEgLV3P .mdc-button {
  --mdc-theme-primary: var(--klfa-primary);
}

._3kVQwm9FzzWTuimwGqS-Pc {
    cursor: move;
    user-select: none;
    -webkit-user-select: none;
}

._2iGSiqC6s2kM6gBT1ptrBf {
    border: solid 1px black;
    flex-grow: 1;
    position: relative;
}


.kalfa-cell-dragToBucket {
  display: flex;
  flex-direction: column;
}

._5ea6aixi8fv0FEUJKUKr9 {
  width: 100%;
  position: relative;
}

@media only screen and (min-width: 600px) {
  ._5ea6aixi8fv0FEUJKUKr9 {
    display: flex;
  }
}

._1QflXXoHcBbdVfUzP87J_G {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.kalfa-dragtobucket-statement {
  margin-bottom: 0.5em;
}

.NX5ZWxLfJm25061mbs5NU {
  display: flex;
  width: 100%;
}

.NX5ZWxLfJm25061mbs5NU .mdc-drawer {
  border: 0;
}

.NX5ZWxLfJm25061mbs5NU .user {
  background-color: var(--klfa-color-red);
  color: white;
  cursor: pointer;
  padding-right: 0.5em;
  margin: 0 1em 0.5em 0;
  width: auto;
  display: flex;

  /* justify-content: space-between; */

  /* opacity: 0.8; */
}

.NX5ZWxLfJm25061mbs5NU .user:hover {
  opacity: 1;
  color: white;
}

.NX5ZWxLfJm25061mbs5NU .user.selected {
  color: white;
  opacity: 1;
}

.NX5ZWxLfJm25061mbs5NU .user.correct {
  background-color: var(--klfa-color-green);
}

.NX5ZWxLfJm25061mbs5NU .user .mdc-chip__icon--trailing {
  margin: 0 0 auto auto;
}

._24JXy_nwZvYUIgOUJQ_u07 {
  display: flex;
  flex-direction: column;
  margin-right: 25px;
}

.rWMXmcJFC_bjK8l8pBTo4 {
  flex-grow: 1;
  min-height: 300px;
}

.rWMXmcJFC_bjK8l8pBTo4 img {
  width: 100%;
  height: auto;
}

._3MXmBVhk5LD-LXYwQ3XZ2p {
  flex: 1 1;
  display: flex;
  height: 100%;
  place-content: center center;
}

._3MXmBVhk5LD-LXYwQ3XZ2p span {
  align-self: center;
}

.kalfa-essay-textfield .mdc-text-field--textarea .mdc-floating-label {
  background-color: inherit;
}

.kalfa-essay-textfield textarea {
  margin-top: 0 !important;
  background-color: whitesmoke;
}
.kalfa-quiz-buttons {
  display: flex;
  flex-direction: row;
}

.kalfa-quiz-button {
  flex: 1 0;
  margin: 1rem;
}
.kalfa-cell-figure {
  text-align: center;
}

.kalfa-cell-figure,
.kalfa-cell-figure img
 {
  max-width: 100%;
  margin: 0 auto;
}
.kalfa-cell-audio {
}


.drag-and-sort-draggable {
  user-select: none;
  -webkit-user-select: none;
}


.kalfa-cell-video {
    width: 100%;
}

.kalfa-cell-video > video {
    max-width: 100%;
}


.kalfa-cell-youtube {
  border: 5px solid #cf2203;
  background-color: #cf2203;
  max-width: 100%;
  margin: 0 auto;
}

.kalfa-cell-youtube img {
  margin: 10px;
}

.youtube-video {
  border: 5px solid #cf2203;
  background-color: #cf2203;
  border-top: none;
  height: 0;
  margin-bottom: 1em;
  padding-bottom: 56.25%;
  padding-top: 50px;
  position: relative;
}

.youtube-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.kalfa-cell-paint {
  /* height: 650px; */
}

.body-paint-workaround {
  height: 100%;
  overflow: hidden !important;
}
.kalfa-cell-carousel {
    background: #e2e2e2;
}

.slick-next {
    right: 0;
}

.slick-prev {
    left: 0;
    z-index: 4;
}

._10wZJRgcfxa8h22K3aYKyL {
    margin: -1em;
    background: #8b8b8b;
    padding: 5px;
    display: flex;
    justify-content: flex-start;
    color: white;
    cursor: pointer;
    line-height: 1.5em;
  }
  
  ._1JTezfF3mgVZ2fav_AcN8z {
    margin-bottom: 1em;
  }
.kalfa-cell-geogebra {
    z-index: 0;
}
._2o9fk-RT-bdT0LKUy-8pT1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background-color: var(--klfa-content-background);
  /* position: relative; */
}

.klfa-default-content-container {
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: calc(100% - 2em); /* to account for extra padding */
  max-width: 1200px;
  padding: 1em;
  background-color: var(--klfa-content-background);
}

.egi-footer {
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  /* width: 100vw; */
  background-color: #534f48;
  color: rgba(255, 255, 255, 0.6);
  padding: 15px 0;
  /* background-color: var(--klfa-content-background); */
}

.egi-footer img {
  max-height: 35px;
}

._2_xUU9qMl0oBdIXnQCjhRI {
  height: 2em;
}

._8byaNGYuwpHMbLfwzQW3n.mdc-top-app-bar--fixed {
  --mdc-theme-primary: #444;
  /* position: sticky; */
  top: 0;
  /* overflow: hidden; */
}

._8byaNGYuwpHMbLfwzQW3n .mdc-button {
  --mdc-theme-primary: var(--klfa-content-background);
}

/* :local(.topbar)::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: red;
  bottom: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    90deg,
    #131313,
    #ffed00,
    #33325e,
    #f59d24,
    #61bfe2,
    #e6007e,
    #9ac31c
  );
} */

.klfa-dashed-line {
  max-width: 100vw;
  overflow: hidden;
  /* z-index: 9999; */
  align-self: stretch;
  background-image: url("/images/dashed-line.svg");
  background-repeat: repeat-x;
  background-position-x: center;
  background-position-y: center;
  height: 10px;
  /* position: sticky; */
  overflow: hidden;
  top: 65px;
}

.klfa-dashed-line.smaller {
  top: 55px;
}

.klfa-dashed-line-short {
  max-width: 100%;
  overflow: hidden;
  /* z-index: 9999; */
  background-image: url("/images/dashed-line.svg");
  background-repeat: repeat-x;
  background-position-x: center;
  background-position-y: center;
  height: 10px;
}

@media only screen and (max-width: 500px) {
  .egi-footer {
    flex-direction: column;
  }
}
.klfa-join-course-container {
  display: flex;
  margin: auto auto;
}

/* disatble spinner on text field */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
  -moz-appearance:textfield;
}
._2K-gdi0wyzgdpKtKxw98TL {
    width: 400px;
    margin: auto;
}

._3f3Td-SU_CXSCJFudWH5jE {
    font-size: 40px;
    color: #6d6d6d;
}

._3HzeTUmxqr_UphwYku88FU {
    display: flex;
    align-items: center;
}

._2K-gdi0wyzgdpKtKxw98TL .mdc-list--two-line .mdc-list-item {
    height: 80px;
}

._1U5PmmGR6kLMUMcLKC4wCl {
    background: white;
    margin-bottom: 10px;
    border-radius: 5px;
    padding-bottom: 10px !important;
}
.vZPHi3xYpLuPyii1k40z8 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1.5em;
}

.vZPHi3xYpLuPyii1k40z8 h1 {
  font-size: 1.5em;
}

._254Id9l4v2F9uqoouwcJGc {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 3px;
}

._2dgej3EViwV_G3IXSKmh2G {
  background: white;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px 0;
  width: 25px;
  height: 25px;
  padding: 2px 0 0 2px;
  color: var(--mdc-theme-primary);
}

._2dgej3EViwV_G3IXSKmh2G i{
  border-radius: 50%;
}

@media only screen and (min-width: 1270px) {
  ._254Id9l4v2F9uqoouwcJGc {
    margin: 10px 0;
    margin-left: -10px;
    position: relative;
  }
  
  ._2dgej3EViwV_G3IXSKmh2G {
    width: 30px;
    height: 30px;
    padding: 7px 0 0 7px;
    position: absolute;
    top: -17px;
    left: -35px;
  }  
}

._3hCDqjdipTqf0NITpdKlyb {
  margin-right: 10px;
}

._254Id9l4v2F9uqoouwcJGc :hover {
  background-color: #eaeaea;
  cursor: pointer;
}

._3dGrU-3EM9y8AgGymZYdkr {
  margin: 0 !important;
}
._1MrhvppPCzUM8Z5AQdVyGM {
  text-decoration: none;
  color: inherit;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background-color: rgba(0,0,0,0);
  cursor: pointer;
  border: 0;
}

._30ZS5qTe-L6L-mKuIn6EbA {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

._24KFXMp4QbnVCIkqgVjThF {
  height: 56px;
  flex-shrink: 0;
  background-color: var(--klfa-secondary);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

._24KFXMp4QbnVCIkqgVjThF span {
  text-transform: uppercase;
}

._2-N_rJRFPUfbBhWe6ykWs7 {
  margin: 0 auto;
  margin-top: 20px;
  background-color: transparent !important;
}

.cpF-Lw4vJTJlg_8z0rgBk {
  background-color: rgba(255, 255, 255, 1);
  padding: 1em;
  position: relative;
  box-shadow: 0 2px 4px 0 #00000080;
  margin-bottom: 10px;
}

.nLUNyCZ_bU648GStYMwTa {
  border: 1px solid white;
  border-radius: 5px;
  --mdc-theme-primary: white;
  --mdc-theme-on-primary: var(--klfa-secondary);
}
.klfa-toc-list-item.mdc-list-item {
  display: flex;
  cursor: pointer;
}

.klfa-toc-list-item .mdc-list-item__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  /* white-space: nowrap; */
}
.klfa-toc-list-item .mdc-list-item__primary-text {
  margin: 0;
}

.klfa-toc-list-item .mdc-list-item__primary-text::after,
.klfa-toc-list-item .mdc-list-item__primary-text::before {
  display: none;
}

.klfa-toc-list-item .mdc-list-item__secondary-text {
  margin-top: -0.4em;
}

.klfa-toc-list-item.klfa-indent {
  margin-left: 2em;
}

.klfa-toc-list-item .mdc-list-item__graphic {
  margin-right: 0.5em;
}

.cpB99HnRoNW8tY48jjqCI {
  background-color: #ffd40061 !important;
}

._3aB0jYvxO6x8WmI86oB2D2 {
  position: fixed;
  z-index: 10;
}

._3aB0jYvxO6x8WmI86oB2D2 div[role="menuitem"] {
  margin: 0;
  font-weight: normal;
}

/*
.mdc-list-item {
  font-family: Roboto;
  font-size: 1.1rem;
  font-weight: bold;
  margin: 5px;
  background-color: white;
  height: 50px;
  --mdc-theme-text-icon-on-background: black;
  --mdc-theme-text-secondary-on-background: black;
  --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.6)
} */

._3pHtHx9CA-UqdLkdB5Xt71 {
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
  /* width: 377px; */
}

._1dUpfOajvK5IweFEdos3fz {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #4a4a4a;
  color: white;
  text-transform: uppercase;
  padding: 0 1rem;
  flex-shrink: 0;
  height: 56px;
}

.xKv9CMHivpk2_6jHVlYV5 {
  position: fixed;
}

.oL8Ow-m_znKIDi0m1ReMe img {
  width: 20px;
  height: 20px;
}

.SWMXshmbTmk_qPYsQPYBm {
  overflow-y: auto;
}


.I5j2Gm4XnmU7wrF0OI1v7 {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.I5j2Gm4XnmU7wrF0OI1v7 .mdc-top-app-bar {
  position: static;
  flex-shrink: 0;
}

.X7ONJFUf041Tav4uEuVTT {
  display: flex;
  flex-grow: 1;
}

._3SvOJCxs1KYl4Ei_gJgXbT {
  flex: 1 0;
  background: #f1f1f1;
  box-shadow: -2px 0 7px 1px #0000003b, 2px 3px 7px 1px #0000002b;
}

._2mgFSd-VleAJyMiQLZu-ja {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  font-size: 2em;
}

._3-MOKbUraeMdQuTma8Q5DG {
  width: 380px;
}

.xeh1UYewBjM0PagX1Xm-m.mdc-top-app-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
/*
:local(.topAppBar)::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: red;
  bottom: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    90deg,
    #131313,
    #ffed00,
    #33325e,
    #f59d24,
    #61bfe2,
    #e6007e,
    #9ac31c
  );
} */

.xeh1UYewBjM0PagX1Xm-m .mdc-button {
  --mdc-theme-primary: var(--klfa-primary);
}

._2IShAXmx0Bt6RzS8Frw2u4 input {
  margin-top: 0 !important;
}
._1XUtFwwa4HB06CszGYhMuD {
    width: 100%;
}

._1BIFZaFJlxmVd8m4Rn4Y1M {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}

._1BIFZaFJlxmVd8m4Rn4Y1M iframe {
    position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
._3GoY76t2IecQrHAqcY6w2I {
    width: 100%;
}
._1CTpcBIWkCwSiDcP54Vcos {
    margin-bottom: 15px;
}

._1fRLpUUDa_9YqQnAn9GkqU .mdc-text-field__input {
    margin-top: 0 !important;
}

.inCRc8ye9r-Jrcqy-90UF {
    margin-top: 0 !important;
}

.inCRc8ye9r-Jrcqy-90UF textarea {
    background-color: whitesmoke;
}
@media only print {
  ._21zHM-bNa4T-_rCtLEOXSn {
    display: none;
  }
}

._3T9kCMpi7jzkBaIMymzzri {
  margin-right: 10px;
}


._21zHM-bNa4T-_rCtLEOXSn {
  background: white;
  padding: 10px;
  border: 1px solid black;
  margin-bottom: 10px;
}

._34lIFNbXkN8v189AWB88ma {
  z-index: 100;
}
.kalfa-page-view {
  max-width: 720px;
  margin: 0 auto;
  margin-bottom: 100px;
}

._38SmhBUVMYF3LHpqLZkWo3 {
  background-color: rgba(255, 255, 255, 1);
  padding: 1em;
  position: relative;
  box-shadow: 0 2px 4px 0 #00000080;
  margin-bottom: 10px;
}
.NJLNuzUlWUrf1kh00m3XM {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

._2CAvPYiMkq2T3CFChGuXzA {
  flex-grow: 1;
  background: #f1f1f1;
}

.NJLNuzUlWUrf1kh00m3XM .mdc-top-app-bar {
  position: static;
  flex-shrink: 0;
}

.kTvuQAHuIzsYkeHoB5Era {
  display: flex;
  flex-grow: 1;
}

._39Smr7fBbjHrICiyyV_b_k {
  flex: 1 0;
  background: #f1f1f1;
  box-shadow: -2px 0 7px 1px #0000003b, 2px 3px 7px 1px #0000002b;
}

._1pDGTihynWZCGytrJygPBr {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  font-size: 2em;
}

.bj_n6tzYeNbxEqt5PuvdT {
  max: 380px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

._2USl3e5rlcXdrxKAl6EkMt.mdc-top-app-bar {
  --mdc-theme-primary: white;
  color: var(--klfa-primary);
  /* position: sticky; */
  top: 0;
}

/* :local(.topAppBar)::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: red;
  bottom: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    90deg,
    #131313,
    #ffed00,
    #33325e,
    #f59d24,
    #61bfe2,
    #e6007e,
    #9ac31c
  );
} */

._2USl3e5rlcXdrxKAl6EkMt .mdc-button {
  --mdc-theme-primary: var(--klfa-content-background);
}

._1B8RQyYjyqGwskstcewtXB {
  display: flex;
  flex-direction: row;
  height: 100%;
}

._27KdF-F4-VRaNpvhuc8CpM {
  flex: 0 0 50%;
  padding: 1em;
}

._2ty_ZEZePve-NCYb_B-IIN {
  width: 40%;
  flex: 0 0 40%;
  padding: 1em;
}

._2xGshvCN-8YPiC9FnwAt0R {
  display: flex;
  flex-direction: row;
  align-items: center;
}

._178NRI9DwA-iL3umDjGKQN {
  align-self: flex-start;
}

._1lqqdOAvaCIfhMIaOT350Y {
  margin-left: auto;
}
._3iFGdtnGJJAzcd_1Xt3ZRs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

._3iFGdtnGJJAzcd_1Xt3ZRs h1 {
  font-size: 1.5em;
}


.D0g6PAHS84Y6TfBIR6-yE {
  border-width: 0;
  margin: 1em 0;
  border-top: 1px solid var(--klfa-content-background);
}

._2AsjAW3zMYzqx_feoxMw3O {
  display: grid;
  grid-template-columns: repeat(auto-fill, 288px);
  grid-gap: 1em;
}

.mdc-linear-progress {
  margin-left: 1em;
  flex: 1 1;
}

.mdc-select__native-control {
  background: white;
  border-radius: 5px;
  padding: 16px;
  width: 100%;
}

.mdc-dialog__container {
  width: 80% !important;
}

@media only screen and (max-width: 800px) {
  .mdc-dialog__container {
    width: 100% !important;
  }
}

.mdc-dialog__surface {
  width: 100% !important;
}
._2cc62PFOucjSc6ZQd89bpl {
  border-radius: 2px;
  justify-content: space-between;
  /* height: 560px; */
  /* width: 340px; */
  margin-right: 25px;
  margin-bottom: 25px;
}

._2cc62PFOucjSc6ZQd89bpl:hover .HTmr5NDPWQ10etBc7iY7J {
  /* border: solid black 1px; */
  background: var(--mdc-theme-primary);
  color: white;
}

.HTmr5NDPWQ10etBc7iY7J {
  border-radius: 5px;
  position: absolute;
  top: 10px;
  background: white;
  right: 10px;
  font-size: 2em;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  padding: 0 0.3em;
  z-index: 1;
  cursor: pointer;
}

._28c0Xcg7Ia0T7MTc4YAZjl.mdc-card__media {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

._1TVWp6ZML3bdcmrW_Op7jQ {
  padding: 0 1rem 1rem 1rem;
}

._1afYXLEuGv4r2w9k6vTn-_ {
  margin-top: -1rem;
}

._1-MroAYk0nIjgXZFSgniGs {
  display: flex;
}

._1-MroAYk0nIjgXZFSgniGs img {
  border-radius: 3px;
}

._23d5syRzahj6L5r1kyjhqF {
  --mdc-theme-primary: #000;

  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

._2yFYzBCt5RriCxVqyhfx5q {
  width: 98px;
}

._3CFYIkirTzSD7Zqsqj_8rl {
  font-size: 1.5rem;
}

.mdc-linear-progress {
  margin-left: 0;
  margin-top: 1rem;
}

.mdc-chip-set {
  margin-bottom: 1.5rem;
}

._2cc62PFOucjSc6ZQd89bpl :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded i {
  margin-right: 1rem;
}

.mdc-card > a {
  color: var(--mdc-theme-secondary);
  text-decoration: none;
}

@media only print {
    ._3YodoIpSo9HEr-mL3m7i49 {
        display: none;
    }
}

._3YodoIpSo9HEr-mL3m7i49 {
    position: fixed;
    right: 30px;
    bottom: 30px;
}

._3lXt9CjVqMJFhuNHQHgmnh {
    width: 56px;
    height: 56px;
    background-color: var(--klfa-secondary);
    color: white;
    border-radius: 50%;
    font-weight: bold;
}

._1qB-0NX5ayS7QaT6amWkHp {
    width: 56px;
    height: 56px;
    background-color: var(--klfa-primary);
    color: white;
    border-radius: 50%;
    font-weight: bold;
    margin-left: -10px;
}
.rHxCzyMkFvn2fPdjVs5DR {
  max-width: 100%;
  margin: 0 auto;
}

._18z80_WZ5VxQVlgCUiNth3 {
  cursor: zoom-in;
}

._17ZXRjlqG6rtc_2aQE4zjN {
  text-align: center;
}
._3bdda4Bv8dvj6lD6dsWG0R {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem 1rem 0 1rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

._3bdda4Bv8dvj6lD6dsWG0R ::-webkit-scrollbar {
  width: 8px;
}

/* Track */
._3bdda4Bv8dvj6lD6dsWG0R ::-webkit-scrollbar-track {
  background: var(--klfa-secondary)f0;
  border-radius: 10px;
}

/* Handle */
._3bdda4Bv8dvj6lD6dsWG0R ::-webkit-scrollbar-thumb {
  background: #9b9b9b;
  border-radius: 10px;
}

/* Handle on hover */
._3bdda4Bv8dvj6lD6dsWG0R ::-webkit-scrollbar-thumb:hover {
  background: #777676;
}

.jqU_UN9p7HW47DMoefB8J {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

._27QrC90CncxWKSLlHdhH36 i {
  color: grey;
  cursor: pointer;
  opacity: 0.6;
  align-self: flex-start;
}

._27QrC90CncxWKSLlHdhH36 i:hover {
  opacity: 1;
}

._27QrC90CncxWKSLlHdhH36 {
  display: flex;
  justify-content: space-between;
  color: black;
  background: white;
  margin: 1em auto;
  padding: 1em;
  border-radius: 5px;
}

._2lLl9MIvEomG7uvdxQReJ7 {
  display: flex;
  align-items: center;
  flex-grow: 1;
  background: rgba(255, 255, 255, .2);
  border: 2px solid transparent;
  border-radius: 5px;
  padding: .5em;
}

._2lLl9MIvEomG7uvdxQReJ7:focus-within {
  border-color: white;
}

._2lLl9MIvEomG7uvdxQReJ7 textarea {
  resize: none;
  color: white;
  background: transparent;
  border: none;
  width: 100%;
  outline: none;
}

.jo44K5mqqidqykI9YRlUM i {
  cursor: pointer;
  background-color: transparent;
  border-radius: 50%;
  padding: 0.3em;
}

.jo44K5mqqidqykI9YRlUM i:hover {
  background-color: var(--klfa-primary);
}

.jo44K5mqqidqykI9YRlUM {
  /* flex-shrink: 0; */
  display: flex;
  /* margin-top: 20px; */
  margin-bottom: 20px;
  width: 100%;
  /* justify-content: space-between; */
  /* align-items: center; */
  /* color: white; */
  /* border: solid 2px; */
  /* border-radius: 5px; */
  /* padding: 0 5px; */
  /* padding: 1em; */
  /* background: red; */
  --mdc-theme-text-primary-on-light: black !important;
}

.DYmanhw6aZpCBBTDOC9XO {
  margin-left: 1em;
  display: flex;

  /* align-items: center; */
  /* justify-content: center; */
}
.DYmanhw6aZpCBBTDOC9XO i {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: rgba(255, 255, 255, .2);
  width: 40px;
  /* height: 100%; */
  /* width: 60px; */
  /* border-radius: 0; */
}

.DYmanhw6aZpCBBTDOC9XO i:hover {
  background: var(--klfa-primary);
}

._3RSqyGck959CNtXDzL6-1f {
  color: black;
}

.Be_Jkjl08rCCYqBsxyfSg {
  color: white;
}

._2kw9uaMYgY5tWEthLSOxvx {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 1em;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

._3WsQ1WmqzNleCHT5ZT8AIy {
  flex-basis: 87px;
  flex-grow: 0;
  flex-shrink: 0;
  background-size: cover;
}

._32NvQ7hoUQkzUSQ1vPP9_v {
  background-color: var(--klfa-secondary);
  color: var(--mdc-theme-text-primary-on-light);
  width: 400px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100vh - 56px);
  position: relative;
  --mdc-theme-on-surface: white;
  --mdc-theme-text-primary-on-light: white;
  --mdc-theme-text-primary-on-background: white;
  --mdc-theme-text-secondary-on-background: white;
}

._8MO5UzPi8JnOLRBdnqqQP {
  padding: 0 1rem;
}

._1-yJ0IwA2EyRo0kbozXpOJ {
  display: flex;
  justify-content: space-between;
  --mdc-theme-primary: white;

}

.wsgqPTQjvCOq6A-3M7QCi {
  padding: 1em;
  font-family: "Zapfino";
}

._1-yJ0IwA2EyRo0kbozXpOJ .mdc-tab-bar {
  margin: 0;
}

@media only screen and (max-width: 800px) {
  ._32NvQ7hoUQkzUSQ1vPP9_v {
    height: auto;
    width: 100%;
  }
}

/* Top Bar */
.TxHND6klxYYI8sQIYFZ-g.mdc-top-app-bar--fixed {
  top: 0;
  /* position: sticky; */
  /* overflow: hidden; */
}

/* :local(.topAppBar)::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: red;
  bottom: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    90deg,
    #131313,
    #ffed00,
    #33325e,
    #f59d24,
    #61bfe2,
    #e6007e,
    #9ac31c
  );
} */

.mdc-top-app-bar__section .mdc-button {
  --mdc-theme-primary: var(--klfa-content-background);
}

.TxHND6klxYYI8sQIYFZ-g .mdc-top-app-bar__row {
  height: 56px;
}

._3WIZ09EKFbd_z9d8tcTb7p {
  max-width: 26px;
}

._3WIZ09EKFbd_z9d8tcTb7p a {
  color: var(--klfa-primary);
}

._2MJ5eeEt4FGq5R2lF0VyCz i,
._2MJ5eeEt4FGq5R2lF0VyCz .mdc-button--unelevated .mdc-button__icon,
._2MJ5eeEt4FGq5R2lF0VyCz .mdc-button .mdc-button__icon {
  margin: 0;
}

._1-VSGnjZxdm804vyOAkrDA.mdc-top-app-bar__section {
  width: 400px;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  padding: 0;
}

.I0_LKwP4t0jh0MmzqpUDa {
  background-color: var(--klfa-secondary);
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.I0_LKwP4t0jh0MmzqpUDa i {
  padding: 0 1rem;
}

._2QNRg9qkX1kMnsvYUBwdXM {
  display: flex;
  align-items: center;
}

.I0_LKwP4t0jh0MmzqpUDa i {
  cursor: pointer;
}

.JAbcf72LLYT4NROLMvdwA .mdc-list-item {
  white-space: nowrap;
}

.JAbcf72LLYT4NROLMvdwA .mdc-list-item .material-icons {
  margin-right: 0.5rem;
}

.sD1QWOFoXzHBb_YrR4bAr .mdc-button {
  --mdc-theme-primary: var(--klfa-primary);
}

._1Kw7AkWjcRjRbcQ7ZAAjpU.mdc-drawer--modal {
  --mdc-theme-text-primary-on-background: white;
  background-color: var(--mdc-theme-secondary);
  /* position: relative; */
  color: var(--mdc-theme-text-primary-on-background);
  /* min-height: 130px; */
}

._1Kw7AkWjcRjRbcQ7ZAAjpU .mdc-drawer__header,
._1Kw7AkWjcRjRbcQ7ZAAjpU .mdc-list-item__meta,
._1Kw7AkWjcRjRbcQ7ZAAjpU .mdc-list-item__text,
._1Kw7AkWjcRjRbcQ7ZAAjpU .material-icons.mdc-list-item__graphic,
._1Kw7AkWjcRjRbcQ7ZAAjpU .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-primary-on-background);
}

._1Kw7AkWjcRjRbcQ7ZAAjpU .material-icons.mdc-list-item__graphic {
  margin-right: 0.5em;
}

._1Kw7AkWjcRjRbcQ7ZAAjpU .mdc-drawer__header {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
}

._1Kw7AkWjcRjRbcQ7ZAAjpU .mdc-drawer__header h3 {
  margin: auto 0;
}

._1Kw7AkWjcRjRbcQ7ZAAjpU .mdc-drawer__header .mdc-button__icon {
  margin-right: 0;
}

._1Kw7AkWjcRjRbcQ7ZAAjpU .mdc-drawer__header .mdc-button--unelevated {
  background-color: transparent;
  padding-left: 0.4em;
}

._1Kw7AkWjcRjRbcQ7ZAAjpU .mdc-list-divider {
  background-color: var(--mdc-theme-text-primary-on-background);
}

._1Kw7AkWjcRjRbcQ7ZAAjpU .mdc-list-item__text {
  align-self: auto;
}
._1Kw7AkWjcRjRbcQ7ZAAjpU .mdc-list-item__secondary-text {
  color: rgba(255, 255, 255, 0.4);
}

._1Kw7AkWjcRjRbcQ7ZAAjpU .mdc-dialog__header__title {
  color: var(--klfa-primary);
}

._1Xng6x79-29KcUHpTy6_Ai {
  max-height: 100px;
  /* background-attachment: fixed; */
  background-size: 100%;
  display: block;
  /* position: absolute;
  left: 0; */
  top: 0;
  width: 100%;
  /* z-index: 1; */
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
}

._2UnawqWWaU0u1IErct1abu {
  background-color: inherit;
}

._1KmCo0qVIHG-V76BYyZv32 {
  display: block;
  width: 100%;
  padding: 1em;
}

.mdc-dialog--open {
  visibility: visible;
  z-index: 500;
}

.H3tFDdU1i20Zn52dHNTO9 {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  overflow: hidden;
}

/* :local(.topAppBar)::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: red;
  bottom: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    90deg,
    #131313,
    #ffed00,
    #33325e,
    #f59d24,
    #61bfe2,
    #e6007e,
    #9ac31c
  );
} */

.H3tFDdU1i20Zn52dHNTO9 .mdc-button {
  --mdc-theme-primary: var(--klfa-content-background);
}

._2a9AKa_O9GJ7cmbUuVlZaL {
  min-height: 100vh;
  max-width: 100vw;
  background-color: var(--klfa-content-background);
}

._39bJav_plrLRKtg2aYUNk2 {
  max-width: 450px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

._1AH42m3A1DNOM2Mh4M7BNk {
  margin-top: 46px;
  display: flex;
  flex-direction: row;
  background-color: var(--klfa-content-background);
  max-height: calc(100vh - 56px);
  overflow: hidden;
  height: 100%;
}

._3TKN9UQZiSqzjSHWVcTgAB {
  margin-left: 50px;
  max-width: 400px;
  flex-shrink: 0;
}

/* Hack to overcome the problem with the sidebar showing on
  top of other dialogs on iPad Safari
*/
body.mdc-dialog-scroll-lock ._3TKN9UQZiSqzjSHWVcTgAB,
body.mdc-dialog-scroll-lock ._39bJav_plrLRKtg2aYUNk2 {
  /* display: none; */
  z-index: auto;
}

/* Hack to overcome the problem with the sidebar showing on
  top of other dialogs on iPad Safari
  See above

  UPDATE: (cdinu) I made it 10 so that the page style editor works
*/
body.mdc-dialog-scroll-lock .mdc-top-app-bar {
  z-index: 10;
}

.p08A1KqZceMOSK1EdhQ8y {
  flex-grow: 1;
  padding-top: 0.5em;
  /* padding-bottom: 100px; */
  background-color: inherit;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

