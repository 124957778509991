:local(.container) {
  min-height: 100vh;
  max-width: 100vw;
  background-color: var(--klfa-content-background);
}

:local(.tableOfContents) {
  max-width: 450px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

:local(.contentsContainer) {
  margin-top: 46px;
  display: flex;
  flex-direction: row;
  background-color: var(--klfa-content-background);
  max-height: calc(100vh - 56px);
  overflow: hidden;
  height: 100%;
}

:local(.sidebar) {
  margin-left: 50px;
  max-width: 400px;
  flex-shrink: 0;
}

/* Hack to overcome the problem with the sidebar showing on
  top of other dialogs on iPad Safari
*/
body.mdc-dialog-scroll-lock :local(.sidebar),
body.mdc-dialog-scroll-lock :local(.tableOfContents) {
  /* display: none; */
  z-index: auto;
}

/* Hack to overcome the problem with the sidebar showing on
  top of other dialogs on iPad Safari
  See above

  UPDATE: (cdinu) I made it 10 so that the page style editor works
*/
body.mdc-dialog-scroll-lock .mdc-top-app-bar {
  z-index: 10;
}

:local(.contents) {
  flex-grow: 1;
  padding-top: 0.5em;
  /* padding-bottom: 100px; */
  background-color: inherit;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
