.kalfa-cell-dragToBucket {
  display: flex;
  flex-direction: column;
}

:local(.widget) {
  width: 100%;
  position: relative;
}

@media only screen and (min-width: 600px) {
  :local(.widget) {
    display: flex;
  }
}

:local(.viewOnly) {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.kalfa-dragtobucket-statement {
  margin-bottom: 0.5em;
}
