:local(.card) {
  border-radius: 2px;
  justify-content: space-between;
  /* height: 560px; */
  /* width: 340px; */
  margin-right: 25px;
  margin-bottom: 25px;
}

:local(.card):hover :local(.code) {
  /* border: solid black 1px; */
  background: var(--mdc-theme-primary);
  color: white;
}

:local(.code) {
  border-radius: 5px;
  position: absolute;
  top: 10px;
  background: white;
  right: 10px;
  font-size: 2em;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  padding: 0 0.3em;
  z-index: 1;
  cursor: pointer;
}

:local(.cardMedia).mdc-card__media {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

:local(.cardContent) {
  padding: 0 1rem 1rem 1rem;
}

:local(.cardSecondryText) {
  margin-top: -1rem;
}

:local(.bookContainer) {
  display: flex;
}

:local(.bookContainer) img {
  border-radius: 3px;
}

:local(.bookDetails) {
  --mdc-theme-primary: #000;

  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

:local(.bookImage) {
  width: 98px;
}

:local(.bookTitle) {
  font-size: 1.5rem;
}

.mdc-linear-progress {
  margin-left: 0;
  margin-top: 1rem;
}

.mdc-chip-set {
  margin-bottom: 1.5rem;
}

:local(.card) :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded i {
  margin-right: 1rem;
}

.mdc-card > a {
  color: var(--mdc-theme-secondary);
  text-decoration: none;
}
