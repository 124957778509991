@media only print {
  :local(.courseCell) {
    display: none;
  }
}

:local(.button) {
  margin-right: 10px;
}


:local(.courseCell) {
  background: white;
  padding: 10px;
  border: 1px solid black;
  margin-bottom: 10px;
}

:local(.ql-toolbar) {
  z-index: 100;
}