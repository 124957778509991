:local(.edit) {
    margin-bottom: 15px;
}

:local(.essay) .mdc-text-field__input {
    margin-top: 0 !important;
}

:local(.test) {
    margin-top: 0 !important;
}

:local(.test) textarea {
    background-color: whitesmoke;
}