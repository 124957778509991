@media only print {
    :local(.buttons) {
        display: none;
    }
}

:local(.buttons) {
    position: fixed;
    right: 30px;
    bottom: 30px;
}

:local(.previous) {
    width: 56px;
    height: 56px;
    background-color: var(--klfa-secondary);
    color: white;
    border-radius: 50%;
    font-weight: bold;
}

:local(.next) {
    width: 56px;
    height: 56px;
    background-color: var(--klfa-primary);
    color: white;
    border-radius: 50%;
    font-weight: bold;
    margin-left: -10px;
}