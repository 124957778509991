:local(.drawer).mdc-drawer--modal {
  --mdc-theme-text-primary-on-background: white;
  background-color: var(--mdc-theme-secondary);
  /* position: relative; */
  color: var(--mdc-theme-text-primary-on-background);
  /* min-height: 130px; */
}

:local(.drawer) .mdc-drawer__header,
:local(.drawer) .mdc-list-item__meta,
:local(.drawer) .mdc-list-item__text,
:local(.drawer) .material-icons.mdc-list-item__graphic,
:local(.drawer) .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-primary-on-background);
}

:local(.drawer) .material-icons.mdc-list-item__graphic {
  margin-right: 0.5em;
}

:local(.drawer) .mdc-drawer__header {
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
}

:local(.drawer) .mdc-drawer__header h3 {
  margin: auto 0;
}

:local(.drawer) .mdc-drawer__header .mdc-button__icon {
  margin-right: 0;
}

:local(.drawer) .mdc-drawer__header .mdc-button--unelevated {
  background-color: transparent;
  padding-left: 0.4em;
}

:local(.drawer) .mdc-list-divider {
  background-color: var(--mdc-theme-text-primary-on-background);
}

:local(.drawer) .mdc-list-item__text {
  align-self: auto;
}
:local(.drawer) .mdc-list-item__secondary-text {
  color: rgba(255, 255, 255, 0.4);
}

:local(.drawer) .mdc-dialog__header__title {
  color: var(--klfa-primary);
}

:local(.headerImage) {
  max-height: 100px;
  /* background-attachment: fixed; */
  background-size: 100%;
  display: block;
  /* position: absolute;
  left: 0; */
  top: 0;
  width: 100%;
  /* z-index: 1; */
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
}

:local(.tableOfContents) {
  background-color: inherit;
}

:local(.code) {
  display: block;
  width: 100%;
  padding: 1em;
}

.mdc-dialog--open {
  visibility: visible;
  z-index: 500;
}
