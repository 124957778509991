:local(.container) {
  display: flex;
  flex-direction: row;
  height: 100%;
}

:local(.codeEditor) {
  flex: 0 0 50%;
  padding: 1em;
}

:local(.preview) {
  width: 40%;
  flex: 0 0 40%;
  padding: 1em;
}

:local(.bottomButtonBar) {
  display: flex;
  flex-direction: row;
  align-items: center;
}

:local(.visibilityButtonContainer) {
  align-self: flex-start;
}

:local(.saveAndCancelContainer) {
  margin-left: auto;
}