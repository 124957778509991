/* Top Bar */
:local(.topAppBar).mdc-top-app-bar--fixed {
  top: 0;
  /* position: sticky; */
  /* overflow: hidden; */
}

/* :local(.topAppBar)::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: red;
  bottom: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    90deg,
    #131313,
    #ffed00,
    #33325e,
    #f59d24,
    #61bfe2,
    #e6007e,
    #9ac31c
  );
} */

.mdc-top-app-bar__section .mdc-button {
  --mdc-theme-primary: var(--klfa-content-background);
}

:local(.topAppBar) .mdc-top-app-bar__row {
  height: 56px;
}

:local(.backButtonSection) {
  max-width: 26px;
}

:local(.backButtonSection) a {
  color: var(--klfa-primary);
}

:local(.backButton) i,
:local(.backButton) .mdc-button--unelevated .mdc-button__icon,
:local(.backButton) .mdc-button .mdc-button__icon {
  margin: 0;
}

:local(.sidebarButtonContainer).mdc-top-app-bar__section {
  width: 400px;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  padding: 0;
}

:local(.sidebarButtonHeader) {
  background-color: var(--klfa-secondary);
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:local(.sidebarButtonHeader) i {
  padding: 0 1rem;
}

:local(.sidebarButtonHeaderTitle) {
  display: flex;
  align-items: center;
}

:local(.sidebarButtonHeader) i {
  cursor: pointer;
}

:local(.simpleMenu) .mdc-list-item {
  white-space: nowrap;
}

:local(.simpleMenu) .mdc-list-item .material-icons {
  margin-right: 0.5rem;
}

:local(.exitClassroomModal) .mdc-button {
  --mdc-theme-primary: var(--klfa-primary);
}
