:local(.classWall) {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem 1rem 0 1rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

:local(.classWall ::-webkit-scrollbar) {
  width: 8px;
}

/* Track */
:local(.classWall ::-webkit-scrollbar-track) {
  background: var(--klfa-secondary)f0;
  border-radius: 10px;
}

/* Handle */
:local(.classWall ::-webkit-scrollbar-thumb) {
  background: #9b9b9b;
  border-radius: 10px;
}

/* Handle on hover */
:local(.classWall ::-webkit-scrollbar-thumb:hover) {
  background: #777676;
}

:local(.activities) {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

:local(.activity) i {
  color: grey;
  cursor: pointer;
  opacity: 0.6;
  align-self: flex-start;
}

:local(.activity) i:hover {
  opacity: 1;
}

:local(.activity) {
  display: flex;
  justify-content: space-between;
  color: black;
  background: white;
  margin: 1em auto;
  padding: 1em;
  border-radius: 5px;
}

:local(.textareaContainer) {
  display: flex;
  align-items: center;
  flex-grow: 1;
  background: rgba(255, 255, 255, .2);
  border: 2px solid transparent;
  border-radius: 5px;
  padding: .5em;
}

:local(.textareaContainer):focus-within {
  border-color: white;
}

:local(.textareaContainer) textarea {
  resize: none;
  color: white;
  background: transparent;
  border: none;
  width: 100%;
  outline: none;
}

:local(.sendButtonContainer) i {
  cursor: pointer;
  background-color: transparent;
  border-radius: 50%;
  padding: 0.3em;
}

:local(.sendButtonContainer) i:hover {
  background-color: var(--klfa-primary);
}

:local(.sendButtonContainer) {
  /* flex-shrink: 0; */
  display: flex;
  /* margin-top: 20px; */
  margin-bottom: 20px;
  width: 100%;
  /* justify-content: space-between; */
  /* align-items: center; */
  /* color: white; */
  /* border: solid 2px; */
  /* border-radius: 5px; */
  /* padding: 0 5px; */
  /* padding: 1em; */
  /* background: red; */
  --mdc-theme-text-primary-on-light: black !important;
}

:local(.uploadImageButton) {
  margin-left: 1em;
  display: flex;

  /* align-items: center; */
  /* justify-content: center; */
}
:local(.uploadImageButton) i {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: rgba(255, 255, 255, .2);
  width: 40px;
  /* height: 100%; */
  /* width: 60px; */
  /* border-radius: 0; */
}

:local(.uploadImageButton) i:hover {
  background: var(--klfa-primary);
}

:local(.dialogHeader) {
  color: black;
}
