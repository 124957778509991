.klfa-join-course-container {
  display: flex;
  margin: auto auto;
}

/* disatble spinner on text field */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
  -moz-appearance:textfield;
}