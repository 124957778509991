:local(.topAppBar) {
  position: sticky;
  top: 0;
  overflow: hidden;
}

/* :local(.topAppBar)::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: red;
  bottom: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    90deg,
    #131313,
    #ffed00,
    #33325e,
    #f59d24,
    #61bfe2,
    #e6007e,
    #9ac31c
  );
} */

:local(.topAppBar) .mdc-button {
  --mdc-theme-primary: var(--klfa-content-background);
}
