:local(.container) {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

:local(.pageViewWrapper) {
  flex-grow: 1;
  background: #f1f1f1;
}

:local(.container) .mdc-top-app-bar {
  position: static;
  flex-shrink: 0;
}

:local(.sidebar) {
  display: flex;
  flex-grow: 1;
}

:local(.page) {
  flex: 1 0 0;
  background: #f1f1f1;
  box-shadow: -2px 0 7px 1px #0000003b, 2px 3px 7px 1px #0000002b;
}

:local(.placeholder) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  font-size: 2em;
}

:local(.widthToc) {
  max: 380px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

:local(.topAppBar).mdc-top-app-bar {
  --mdc-theme-primary: white;
  color: var(--klfa-primary);
  /* position: sticky; */
  top: 0;
}

/* :local(.topAppBar)::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: red;
  bottom: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    90deg,
    #131313,
    #ffed00,
    #33325e,
    #f59d24,
    #61bfe2,
    #e6007e,
    #9ac31c
  );
} */

:local(.topAppBar) .mdc-button {
  --mdc-theme-primary: var(--klfa-content-background);
}
