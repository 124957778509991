@media only print {
  .klfa-answers--container {
    display: none !important;
  }
}

.klfa-answers--container {
  --klfa-answers-background: var(--klfa-content-background);
  --klfa-answers-background-hover: #c1c1c1;
  display: flex;
  align-items: center;
  background-color: var(--klfa-answers-background);
  padding: 0 0.5em 0 0.8em;
  border-radius: 25px;
  margin-top: 0.8em;
  position: relative;
  border: 0;
  width: 100%;
  outline: none;
  /* padding: .5em; */
}

.klfa-answers--container::before {
  position: absolute;
  display: block;
  top: -7px;
  left: 7px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--klfa-answers-background);
  content: "";
}

.klfa-answers--container:hover,
.klfa-answers--container:focus {
  background-color: var(--klfa-answers-background-hover);
  cursor: pointer;
}

.klfa-answers--container:hover::before,
.klfa-answers--container:focus::before {
  border-bottom-color: var(--klfa-answers-background-hover);
}

.klfa-answers--container .mdc-button {
  white-space: nowrap;
  margin-left: 1em;
  /* margin-right: .5em; */

  text-transform: uppercase !important;
  /* prevent overwrites by cell css */
}

.klfa-answers-progress-bar--container {
  display: flex;
  flex: 1;
  width: 100%;
  height: 6px;
  cursor: pointer;
  box-shadow: 0 0 3px 0 grey;
}

.klfa-answers-progress-bar--container > div {
  flex: 1 1 auto;
}

.klfa-answers__ready {
  width: 1%;
  background-color: var(--mdc-theme-primary);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.klfa-answers__receiving {
  width: 1%;
  background-color: var(--mdc-theme-secondary);
}

.klfa-answers__inactive {
  width: 100%;
  background-color: white;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
