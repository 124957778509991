.kalfa-cell-carousel {
    background: #e2e2e2;
}

.slick-next {
    right: 0;
}

.slick-prev {
    left: 0;
    z-index: 4;
}
