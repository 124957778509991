:local(.container) {
    width: 400px;
    margin: auto;
}

:local(.title) {
    font-size: 40px;
    color: #6d6d6d;
}

:local(.addButton) {
    display: flex;
    align-items: center;
}

:local(.container) .mdc-list--two-line .mdc-list-item {
    height: 80px;
}

:local(.listItem) {
    background: white;
    margin-bottom: 10px;
    border-radius: 5px;
    padding-bottom: 10px !important;
}