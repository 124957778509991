:local(.toggle) {
    margin: -1em;
    background: #8b8b8b;
    padding: 5px;
    display: flex;
    justify-content: flex-start;
    color: white;
    cursor: pointer;
    line-height: 1.5em;
  }
  
  :local(.open) {
    margin-bottom: 1em;
  }