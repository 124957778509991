:local(.cellOverlay) {
  text-decoration: none;
  color: inherit;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background-color: rgba(0,0,0,0);
  cursor: pointer;
  border: 0;
}
