:local(.disableAction) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  z-index: 100;
}

.kalfa-cell {
  position: relative;
}

.klfa-ready-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.klfa-ready-button button::before {
  display: none;
}

:local(.correctnessImage) {
  height: 25px;
}
