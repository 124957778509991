:local(.defaultLayout) {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background-color: var(--klfa-content-background);
  /* position: relative; */
}

.klfa-default-content-container {
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 2em); /* to account for extra padding */
  max-width: 1200px;
  padding: 1em;
  background-color: var(--klfa-content-background);
}

.egi-footer {
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  gap: 15px;
  /* width: 100vw; */
  background-color: #534f48;
  color: rgba(255, 255, 255, 0.6);
  padding: 15px 0;
  /* background-color: var(--klfa-content-background); */
}

.egi-footer img {
  max-height: 35px;
}

:local(.klfaLogo) {
  height: 2em;
}

:local(.topbar).mdc-top-app-bar--fixed {
  --mdc-theme-primary: #444;
  /* position: sticky; */
  top: 0;
  /* overflow: hidden; */
}

:local(.topbar) .mdc-button {
  --mdc-theme-primary: var(--klfa-content-background);
}

/* :local(.topbar)::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: red;
  bottom: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    90deg,
    #131313,
    #ffed00,
    #33325e,
    #f59d24,
    #61bfe2,
    #e6007e,
    #9ac31c
  );
} */

.klfa-dashed-line {
  max-width: 100vw;
  overflow: hidden;
  /* z-index: 9999; */
  align-self: stretch;
  background-image: url("/images/dashed-line.svg");
  background-repeat: repeat-x;
  background-position-x: center;
  background-position-y: center;
  height: 10px;
  /* position: sticky; */
  overflow: hidden;
  top: 65px;
}

.klfa-dashed-line.smaller {
  top: 55px;
}

.klfa-dashed-line-short {
  max-width: 100%;
  overflow: hidden;
  /* z-index: 9999; */
  background-image: url("/images/dashed-line.svg");
  background-repeat: repeat-x;
  background-position-x: center;
  background-position-y: center;
  height: 10px;
}

@media only screen and (max-width: 500px) {
  .egi-footer {
    flex-direction: column;
  }
}