:local(.container) {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

:local(.header) {
  height: 56px;
  flex-shrink: 0;
  background-color: var(--klfa-secondary);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

:local(.header) span {
  text-transform: uppercase;
}

:local(.page) {
  margin: 0 auto;
  margin-top: 20px;
  background-color: transparent !important;
}

:local(.cell) {
  background-color: rgba(255, 255, 255, 1);
  padding: 1em;
  position: relative;
  box-shadow: 0 2px 4px 0 #00000080;
  margin-bottom: 10px;
}

:local(.buttons) {
  border: 1px solid white;
  border-radius: 5px;
  --mdc-theme-primary: white;
  --mdc-theme-on-primary: var(--klfa-secondary);
}